module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ioto-portfolio","short_name":"ioto","start_url":"/","background_color":"#ffffff","theme_color":"#121212","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1940398ba66ba9165970ef7d8769ad54"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ioto-portoflio","accessToken":"MC5ZRnlhdnhBQUFDUUF6OVdQ.77-9GgBk77-9Vu-_ve-_vTtm77-9VSPvv73vv73vv73vv73vv73vv73vv71977-9Tj8BBu-_ve-_vQ1PN3Q","schemas":{"homepage":{"Main":{"title":{"type":"Text","config":{"label":"Title"}},"uid":{"type":"UID","config":{"label":"uid"}},"intro":{"type":"StructuredText","config":{"multi":"paragraph, strong, em, hyperlink","allowTargetBlank":true,"label":"Intro"}},"portfolio_title":{"type":"Text","config":{"label":"Portfolio Title"}},"portfolio":{"type":"Group","config":{"fields":{"project":{"type":"Link","config":{"select":"document","label":"Project"}}},"label":"Portfolio"}},"services_title":{"type":"Text","config":{"label":"Services Title"}},"services_description":{"type":"Text","config":{"label":"Services description"}},"services":{"type":"Group","config":{"fields":{"service":{"type":"Text","config":{"label":"Service"}}},"label":"Services"}},"press_title":{"type":"Text","config":{"label":"Press Title"}},"press":{"type":"Group","config":{"fields":{"publisher":{"type":"Text","config":{"label":"Publisher"}},"link_text":{"type":"Text","config":{"label":"Link Text"}},"link_url":{"type":"Link","config":{"label":"Link URL"}}},"label":"Press"}}}},"layout":{"Main":{"title":{"type":"Text","config":{"label":"Title"}},"uid":{"type":"UID","config":{"label":"uid"}},"link_text":{"type":"Text","config":{"label":"Link Text"}},"link_url":{"type":"Link","config":{"label":"Link URL"}}}},"project":{"Main":{"title":{"type":"Text","config":{"label":"Title"}},"uid":{"type":"UID","config":{"label":"uid"}},"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
